import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`این پروژه را در `}<a parentName="p" {...{
        "href": "https://github.com/For-Iranians/foriranians.de"
      }}><img parentName="a" {...{
          "src": "/icon_github.svg",
          "alt": "GitHub"
        }}></img></a>{` ببینید.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      