import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`صفحه اصلی`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/anmeldung/"
        }}>{`ثبت آدرس`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/bank-account/"
        }}>{`حساب بانکی`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/renting-apartment/"
        }}>{`اجاره کردن خانه`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-hired/"
        }}>{`استخدام در شرکت‌ها`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/public-transportation/"
        }}>{`حمل و نقل عمومی`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/shopping/"
        }}>{`خرید در آلمان`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      